.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    
}

.btn-primary:hover {
    color: #fff;
    background-color: #00A7E1;;
    border-color: #00A7E1;
}

.airport-suggestions {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .airport-suggestions li {
    padding: 10px;
    cursor: pointer;
  }
  
  .airport-suggestions li:hover {
    background-color: #f0f0f0;
  }
  
  .suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
  
/* date picker */
  .react-datepicker-wrapper{
    width: 100%;
  }

  .custom-datepicker {
    width: 100%;
    background-color: white;
  }