body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-top:119px;
}

.carasolParent img{
  width: 100%;
}


.multiCitySearch{
  font-size: 16px;
  background-color: #FB8B24;
  width: 20%;
  padding-left: 5px;
  border-radius: 10px;
}
.selc{
  padding: 10px 20px;
  border-radius: 10px;
}
.carasol img{
 width: 33.3%;
 
}
.custom-color{
  background-color: #00A7E1;
  border: none;
}

.why-use-ticketestan{
  background-color: #00A7E1;
}
/* .side-icon{
  color: white;
  background-color: white;
} */

.content{
  color: black;
}
.quick-link-box a{
  text-decoration: none;
}

.mobile-app{
  background-color: #00A7E1;
}

.earnpoints{
  background-color: 
  #004B65;
}


/* promo-section */

.btn-clr{
  background-color: #FB8B24;
  border-color: #FB8B24;
  color: white;
}

/* top flight */
.list-container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-container a{
  text-decoration: none;
  color: #3498DB;
}
.list-item {
  flex: 1 0 25%; /* Adjust width as needed */
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
}

.footer-section{
  background-color:#003A4F;
}
.hr-color{
  border: 0.5px solid #D7D7D7
}

.socialIcon li{
  list-style: none;
  padding: 20px;
}
.backgroundclrOne{
  background-color:#004B65;
  color: white;
}

.globalclr{
  background: #FB8B24;
  ;
}

.slider-bg{
  background-color: aliceblue;
}


/* custumize bootstrap css */
.custom-font-bold {
  font-weight: 600 !important;
}

.custom-font-normal {
  font-weight: 400 !important;
}

.search-form-section .form-check-label {
  cursor: pointer;
  margin-left: .63em;
}

/* cards.js */

.card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  height: 200px; /* Adjust based on your preference */
  object-fit: cover;
}
.Announcements-banner{
  background-image: url("../src/assets/imgs/AnnoucementsBanner.jpg");
  height: 201px;
}
.terms-banner{
  background-image: url("../src/assets/imgs/terms-banner.jpg");
  height: 201px;
}
/* trains page */
.trains_timming{
  color: #3498DB;
  line-height: 3;
}
.Convenient-bg{
  height: 320px;
  background-image: url('../src/assets/imgs/convenent.png');
  background-repeat: no-repeat;
  background-size: cover;
  
}
.Convenient-col{
  height: 320px;
  background: linear-gradient(72.47deg, #00A7E1 22.72%, rgba(0, 167, 225, 0) 98.55%);
  ;
}
.MovieSection h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.MovieSection .card {
  margin-bottom: 20px;
}
.movie_bg{
  background-image: url("../src/assets/imgs/movies.png");
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  }


@media (min-width: 320px) and (max-width: 480px) {
  .h5, h5 {
      font-size: 16px;
  }
}

@media (max-width: 768px) {
  .carasol img{
    width: 100%;
    margin-bottom: 10px;

  }
  .custom-font-bold {
    font-weight: 500 !important;
  }
}