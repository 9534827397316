.tour_bg{
background-image: url("../imgs/tour.png");
height: 400px;
background-repeat: no-repeat;
background-size: cover;
}
.event-item .card-img .card-img-top {
    min-height: 340px;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all .3s ease-in-out;
}

a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea {
    touch-action: manipulation;
    text-decoration: none;
    color: black;
}


@media (min-width: 1281px) {
    .offcanvas.offcanvas-end {
        width: 50vw !important;
    }
}