.ant-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
}

.ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}
._1FtCRv1FhJXBhUCcfmaQYl {
    transition: all 0.5s ease;
}
._3n2jLcSJVqz9_P8Lnegz58 {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
}
._3ieRhFkskwKOjwu2TsRClY.ant-col {
    min-height: 200px;
}
/* ._3n2jLcSJVqz9_P8Lnegz58 > .ant-col {
    min-height: 0px;
} */
._3ieRhFkskwKOjwu2TsRClY {
    display: flex;
    align-items: center;
}
._3ieRhFkskwKOjwu2TsRClY ._2vSamfXxRgDA9WTZPg-ma5 {
    height: 100%;
}

._11jsErvOCIEmKUnYZO6h3z {
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

._23rHKnVSUs7BGqu-V-Pz9s {
    text-align: center;
}
.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
.ant-row-middle {
    align-items: center;
}
.ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
}
body .text-center {
    text-align: center;
}
.mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}
.text-gray-9 {
    color: #262626 !important;
}
.text-3xl {
    font-size: 1.875rem !important;
}
.ant-typography {
    word-break: break-word;
    font-weight: 600;
}
.i4euHRaOjOWderXLm5y8L .ant-tag, ._3FXo51ef-x57QC76w_HI_g .ant-tag {
    border: none;
    background: #f5f5f5;
    padding: 1px 9px;
    min-width: 68px;
    text-align: center;
}
.i4euHRaOjOWderXLm5y8L ._32EsY-CgIkyDnWGWoIFREG {
    min-width: 0px;
    width: 5px;
    margin: 6px 0 5px 0;
    background: #262626;
}
.ant-row-space-between {
    justify-content: space-between;
}
._3b7ZGVBXspfLBKO3OKHngx .ant-col {
    display: flex;
}
._3b7ZGVBXspfLBKO3OKHngx .st-svg-icon {
    width: 16px;
    height: 16px;
}
.icon-gray-7 svg path, .icon-gray-7 path, .icon-gray-7 {
    fill: #8c8c8c !important;
}
._3b7ZGVBXspfLBKO3OKHngx .info {
    color: #8c8c8c;
}
.text-sm {
    font-size: 0.75rem !important;
}
.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}
.font-secondary {
    font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
._3Zeoqtbn9jD1nk4cjLVwz6 {
    border-left: 1px solid #f0f0f0;
    padding: 0 30px;
    width: 230px;
}
._3Zeoqtbn9jD1nk4cjLVwz6 .right-content-ac-center {
    min-height: calc(100% - 45px);
    align-content: center;
    height: 100%;
}
body .text-right {
    text-align: right;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.ant-tag.text-fill-color-green-1 {
    background-color: #f6ffed;
}
.ant-tag.line-color-green-3 {
    border-color: #b7eb8f;
}
.ant-tag.text-color-green-6 {
    color: #52c41a;
}
.ant-tag.shape-rounded {
    border-radius: 11px;
}
._22Fo9jznUU9L6QlE3jV1z8 {
    color: #135200;
    display: inline-flex;
    align-items: center;
    margin: 0;
    width: max-content;
}
.icon-green-9 svg path, .icon-green-9 path, .icon-green-9 {
    fill: #135200 !important;
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

._22Fo9jznUU9L6QlE3jV1z8 svg {
    height: 14px;
    width: 14px;
}
.anticon svg {
    display: inline-block;
}
img, svg {
    vertical-align: middle;
}
.anticon > * {
    line-height: 1;
}
.ant-tag.text-color-green-6 svg:not([data-icon='close*']) path {
    fill: #52c41a;
}
.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
.ant-tag > .anticon + span, .ant-tag > span + .anticon {
    margin-left: 7px;
}
.ant-btn.ant-btn-block {
    width: 100%;
    height: 41px;
    background: #00A7E1;
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}
.ant-btn-primary {
    color: #fff;
    border-color: #0a549c;
    background: #0a549c;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 4px;
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
    outline: 0;
}
.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
input, button, select, optgroup, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea {
    touch-action: manipulation;
}
button, select {
    text-transform: none;
}
   

@media (min-width: 576px) {
    .ant-col-sm-24 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .ant-col-md-24 {
        display: block;
        flex: 0 0 100%;
        max-width: 80%;
    }
}
@media (max-width: 992px) {
    /* .ant-col-lg-4 {
        display: block;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    } */
    .ant-row-middle {
        align-items: center;
        justify-content: center;
    }
    .text-3xl {
        font-size: 12px !important;
        font-weight: 700;
    }
    ._3Zeoqtbn9jD1nk4cjLVwz6 {
        border-left: none;
    }
    .ant-row-space-between {
        justify-content: center;
    }
}
@media (min-width: 1200px) {
    .ant-col-xl-4 {
        display: block;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }
}
@media (min-width: 1600px) {
        .ant-col-xxl-4 {
            display: block;
            flex: 0 0 16.66666667%;
            max-width: 16.66666667%;
        }
    }