.flightCard{
  display: flex;
  align-items: center;
}

.cards{
  width: 100%;
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  padding: 26px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flightLogo{
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flightName{
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;          
}
.flightNumber{
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
}
.midSection{
padding: 0px 50px;
border-right: 1px solid #E0E0E0;
border-left: 1px solid #E0E0E0;
width: 50%;
display: flex;
align-items: start;
justify-content: center;
flex-direction: column;
}
.originTime{
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.totalTime{
  font-size: 10px;
  background-color: #E1E1E1;
  border-radius: 6px;
  padding: 5px 10px;
  margin-right: 30px;
  margin-left: 15px;
}
.originName{
  font-size: 14px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flightCardLastSection{
  padding: 0px 10px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flightCardButton{
  background-color: #00A7E1;
  padding: 11px 26px;
  border-radius: 4px;
  border: none;
  color: white;

}


@media (max-width: 600px) {
 .cards{
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 0px;
 }
 .midSection{
  border-right: none;
  border-left: none;
 }
 .flightLogo{
  flex-direction: row;
  text-wrap: nowrap;
  margin-right: 140px;
 }
 .flightName{
  margin: 0px 5px;
 }
 .flightNumber{
  margin: 0px 5px;
 }
 .midSection{
  padding: 0px 10px;
  width: 100%;
 }
 .flightCardLastSection{
  border-top: 1px solid #E0E0E0;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  justify-content: end;
 }
}
  @media (max-width: 992px){
    
    
  }



