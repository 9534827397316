/* .add-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.location-details {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.gmap_canvas {
    position: relative;
    width: 100%;
    padding-bottom: 75%; 
    overflow: hidden;
}

.gmap_canvas iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */
.add-map {
    color: #000;
    line-height: 13px;
    padding: 12px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
    margin-bottom: 18px;
    border-radius: 6px;
}
.fontStyle h2, .fontStyle .wp-block-group__inner-container h2 {
    font-family: reem kufi;
    line-height: 22px;
    color: #0a549c;
    padding: 16px 12px;
    font-size: 20px;
    margin: 0;
}
.add-map p {
    line-height: 14px;
    margin-bottom: 5px;
}
.custom-bg{
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 10px;
}
.gmap_canvas {
    overflow: hidden;
    background: none !important;
    box-shadow: #0057987a 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 3px;
}

.aclr{
    color: #00A7E1;
}

@media (max-width: 768px) {
    .custom-bg{
        padding-right: 5px;
        padding-left: 5px;
    }
}