/* .slider-item {
    position: relative;
  }
  
  .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  } */
  
  .slider-item {
    width: 206px;
  
  }
  
  @media (max-width: 768px) {
    .slider-item img{
      width: 70px;
      height: 70%;
    
    } 
  }