#header{
  border-bottom: 1px solid rgb(236, 231, 231);
}
.custom-primary-background {
  background-color: #00A7E1;
}


@media (max-width: 992px) {
  .mbl-res {
    font-size: 11px;
  }
 
}
@media (min-width: 320px) and (max-width: 480px) {
  .mee{
    display: none;
    }
    body {
      padding-top: 80px;
  }
}