
.bg-blac{
    background-color: #020202D9 ;
}
.input-group .form-control {
    border-radius: 8px !important;
    padding-left: 45px;
}

.input-group-text {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    z-index: 51;
}

.bg-car{
    background-image: url(../assets/imgs/bg-car.png);
}