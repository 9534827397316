.announcement-backg{
    background-image: url("../assets/images/Mask\ groupbackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.btnn{
    width: 98px;
    height: 34px;
    background-color: #FB8B24;
    border-radius: 4px;
    padding: 0px, 10px, 0px, 10px;
    border: none;
}
.booking-annu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .booking-annu h2 {
    margin-bottom: 10px;
  }
  
  .booking-annu button {
    margin-bottom: 10px;
  }
  .accordion-body, .accordion-body * {
    color:  #667085;
    
}
.accordion-body a{
  text-decoration: none;
  color: #1476D1;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
}
.text-primary {
  color:  #1476D1 !important;
  
}
  

  @media (max-width: 992px) {
    .booking-annu {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: left;
      }
  }
