.banner{
    background-image: url("../AbouUsBanner.jpg");
    height: 201px;
}

.breadcrumb{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.breadcrumb a{
    color: white;
    font-size: 16px;
}
.img-1{
    width: 100%;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 13% 100%, 0% 89%);
}
.img-2{
    width: 100%;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%, 0% 100%, 0% 20%);
}
.aboutUs-align{
    align-items: center;
}


@media (max-width: 768px) {
    .custom-mb{
        margin-top: 30px;
    }
    .cutom-pt-5{
        padding-top: 0%;
    }
  
    }