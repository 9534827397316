*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container1{
  display: flex;
}
main{
  margin-left: 250px;
  width: 100%;
  transition: all 0.5s;
 
}
.sidebar{
  position: fixed;
  height: 100vh;
  width: 250px;
  transition: all 0.5s;
  border-right: 1px solid rgb(216, 216, 216);
  z-index: 1000;
}

.top_section{
  display: flex;
  align-items: center;
  padding: 20px 15px;
  margin: 10px;
}
.logo{
  display: flex;
  /* margin-left: 50px; */
  
}
.bars{
  font-size: 30px;

}
.link{
  display: flex;
  color: #344054;
  padding: 20px 30px;
  gap:15px;
  transition: all 0.5s;
  text-decoration: none;
  margin-bottom: 2px;
}
.link:hover{
  background-color:  #00A7E1;
  color:white;
  transition: all 0.5s;
}
.active{
  background-color:  #00A7E1;
  color:white;
}
.icon, .link_text{
  font-size: 14px;
}
.fclr{
  color: #5E6362;
  ;
}

@media (max-width: 768px) {
 
  main {
    margin-left: 0; /* Remove left margin */
  }
}



/* .sidebar {
  position: fixed;
  top: 118px;
  height: 100%;
  background-color: #fff;
  padding-top: 20px;
  transition: width 0.3s;
  overflow: hidden;
} */

/* .sidebar .link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
}

.sidebar .link .icon {
  margin-right: 10px;
}

.sidebar .link .link_text {
  display: inline-block;
}

.sidebar .link.active {
  background-color: #f0f0f0;
} */
